<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h1 class="modal-title fs-5">{{ '목적지 선택' | translate }}</h1>
      <button type="button" class="btn-close" (click)="close()"></button>
    </div>

    <div class="modal-body">
      <div class="d-flex region-selector">
        <div class="country-container">
          <div
            class="country"
            [class.selected]="country === selectedCountry"
            (click)="onCountryClick(country)"
            *ngFor="let country of countryList"
          >
            {{ country }}
          </div>
        </div>

        <div class="region-container">
          <label
            class="region"
            (click)="onRegionLabelClick(region, $event)"
            *ngFor="let region of regionList"
            >{{ region }}</label
          >
        </div>
      </div>
    </div>
  </div>
</div>
