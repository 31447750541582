import { Component, ElementRef, OnInit } from '@angular/core';
import { DialogAbstract } from '../dialog.abstract';
import { RegionSelectorService } from './region-selector.service';

@Component({
  selector: 'trnty-region-selector',
  templateUrl: './region-selector.component.html',
  styleUrls: ['./region-selector.component.scss'],
})
export class RegionSelectorComponent extends DialogAbstract implements OnInit {
  countryList?: string[];

  regionList?: string[];

  selectedCountry?: string;

  constructor(
    protected override elementRef: ElementRef<HTMLElement>,
    private regionSelectorService: RegionSelectorService,
  ) {
    super(elementRef);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    const firstCountry = this.regionSelectorService.getCountryList()[0];
    this.setCountryList();
    this.onCountryClick(firstCountry);
  }

  onCountryClick(country: string): void {
    this.selectedCountry = country;
    this.setRegionList();
  }

  /**
   * 지역 선택시
   */
  onRegionLabelClick(region: string, event: MouseEvent): void {
    // 이벤트 전파 중지
    // 중지하지 않으면 부모 컴포넌트 클릭시 이 컴포넌트를 여는 경우, 선택과 동시에 닫으려 해도 안됨
    event.stopPropagation();
    this.close(region);
  }

  private setCountryList(): void {
    this.countryList = this.regionSelectorService.getCountryList();
  }

  private setRegionList(): void {
    if (!this.selectedCountry) {
      return;
    }

    this.regionList = this.regionSelectorService.getRegionList(
      this.selectedCountry,
    );
  }
}
