import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { RegionSelectorComponent } from './region-selector.component';

@NgModule({
  declarations: [RegionSelectorComponent],
  imports: [CommonModule, SharedModule],
  exports: [RegionSelectorComponent],
})
export class RegionSelectorModule {}
