import { Injectable } from '@angular/core';

export const REGION_OBJECT: Record<string, string[]> = {
  일본: [
    '구마모토',
    '나고야',
    '벳부',
    '미야자키',
    '이바라키',
    '유후인',
    '오이타',
  ],
  '동남아/대만/서남아': ['바탐', '쿠알라룸푸르'],
  '중국/홍콩/몽골/중아시아': ['홍콩', '베이징'],
  '괌/사이판/호주/뉴질랜드/피지': ['괌', '사이판', '시드니', '브리즈번'],
  '유럽/아프리카': ['파리', '로마', '런던', '케이프타운'],
};

@Injectable({
  providedIn: 'root',
})
export class RegionSelectorService {
  constructor() {}

  getCountryList(): string[] {
    return Object.keys(REGION_OBJECT);
  }

  getRegionList(country: string): string[] {
    return REGION_OBJECT[country] || [];
  }
}
